.header .swiper-button-next::after,
.header .swiper-button-prev::after {
  font-size: 70px !important;
  color: black;
}

.Special .swiper-button-next::after,
.Special .swiper-button-prev::after {
  font-size: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: black;
  background-color: rgb(255, 255, 255, 0.6) !important;
  padding: 10px !important;
  height: 81px !important;
  width: 81px !important;
}
