@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap");

@font-face {
  font-family: "Sansation";
  src: local("Sansation"),
    url(".././assets/FontFamily/Sansation-Regular.ttf") format("truetype");
}
/* body {
  font-family: "IBM Plex Serif", serif;
} */

.main-container {
  @apply max-w-[1800px] px-5 mx-auto w-full;
}
.footer-widget-title {
  @apply text-[30px] font-bold text-[#ffffff];
}
.bg-selected {
  background-color: #ff6969;
}
.bg-dark {
  background-color: black;
}
.bg-change {
  background-color: transparent;
}
.tx-change {
  color: black;
}
.header-gradient {
  background: radial-gradient(
    38.13% 240.26% at 61.88% 58.29%,
    #f1f1f1 0%,
    #e6e6e6 100%
  );
}
.section-title {
  @apply font-bold text-[36px] capitalize text-primary max-w-max pb-[12px] border-b border-primary;
}

.custom-radio-btn input[type="radio"] {
  display: none;
}

.custom-radio-btn input[type="radio"]:checked + .check-mark {
  box-shadow: inset 0 0 0 2px white;
}
.check-mark {
  @apply inline-flex items-center justify-center rounded-full h-[30px] w-[30px] border border-primary;
}
.collapse {
  @apply max-h-0  transition-all duration-700 ease-in-out overflow-hidden;
}
.open {
  @apply max-h-96 transition-all duration-1000 ease-in-out overflow-hidden;
}
.scroll-width-none {
  scrollbar-width: none;
}
.it-selected {
  cursor: pointer;
}
